body {
  margin: 0;
}

body,
html {
  font-family: "Kanit", sans-serif;
  background-color: #ebebebeb;
}

/**
 * font family
 */
@font-face {
  font-family: "Kanit";
  src: local("Kanit"), url(./assets/fonts/Kanit-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Kanit";
  font-weight: bold;
  src: local("Kanit"), url(./assets/fonts/Kanit-Bold.ttf) format("truetype");
}

/* pageLoading */
figure {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 6.25em;
  height: 6.25em;
  animation: rotate 2.4s linear infinite;
}
.white {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  animation: flash 2.4s linear infinite;
  opacity: 0;
}
.dot {
  position: absolute;
  margin: auto;
  width: 2.4em;
  height: 2.4em;
  border-radius: 100%;
  transition: all 1s ease;
}
.dot:nth-child(2) {
  top: 0;
  bottom: 0;
  left: 0;
  background: #ff4444;
  animation: dotsY 2.4s linear infinite;
}
.dot:nth-child(3) {
  left: 0;
  right: 0;
  top: 0;
  background: #ffbb33;
  animation: dotsX 2.4s linear infinite;
}
.dot:nth-child(4) {
  top: 0;
  bottom: 0;
  right: 0;
  background: #99cc00;
  animation: dotsY 2.4s linear infinite;
}
.dot:nth-child(5) {
  left: 0;
  right: 0;
  bottom: 0;
  background: #33b5e5;
  animation: dotsX 2.4s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  10% {
    width: 6.25em;
    height: 6.25em;
  }
  66% {
    width: 2.4em;
    height: 2.4em;
  }
  100% {
    transform: rotate(360deg);
    width: 6.25em;
    height: 6.25em;
  }
}

@keyframes dotsY {
  66% {
    opacity: 0.1;
    width: 2.4em;
  }
  77% {
    opacity: 1;
    width: 0;
  }
}
@keyframes dotsX {
  66% {
    opacity: 0.1;
    height: 2.4em;
  }
  77% {
    opacity: 1;
    height: 0;
  }
}

@keyframes flash {
  33% {
    opacity: 0;
    border-radius: 0%;
  }
  55% {
    opacity: 0.6;
    border-radius: 100%;
  }
  66% {
    opacity: 0;
  }
}
/* ^pageLoading */

[data-testid="thdpk-input"] {
  /* max-width: fit-content; */
  padding: 0;
  text-indent: 14px;

  height: 55px;
  font-size: 14px;
  caret-color: #990000;
  /* padding-left: 10px; */
  border-radius: 4px;
  border-style: solid;
  border-color: #c2c2c2;
  border-width: 0.5px;
  /* float: left; */

  -webkit-transition: border-color 0.3s;
  -o-transition: border-color 0.3s;
  -moz-transition: border-color 0.3s;
  transition: border-color 0.3s;

  background-color: transparent;
}

.menu-checkbox:checked + .menu {
  transform: translate3d(0, 0, 0);
}

/**
 * alert component
 */
.error {
  color: red;
}

.errorMessage {
  color: red;
  position: absolute;
  bottom: 0;
  margin-bottom: -30px;
}

/**
 * hidden
 */
.none {
  display: none;
}

.w-full {
  width: 100%;
  font-family: "Kanit", sans-serif;
}

/**
 * react-datepicke
 */
.react-datepicker__close-icon {
  padding: 0;
}

.datepicker .react-datepicker__triangle::after {
  border-bottom-color: white !important;
}

.datepicker .react-datepicker__header--custom,
.react-datepicker__aria-live {
  display: none;
}

.datepicker .react-datepicker__month-text--today,
.react-datepicker__year-text--today,
.react-datepicker__day--highlighted-today {
  background-color: #c2c2c2;
  border-radius: 2rem;
  color: black;
}

.datepicker .react-datepicker__month-text--keyboard-selected,
.react-datepicker__year-text--selected,
.react-datepicker__day--selected {
  background-color: #9148c8 !important;
  border-radius: 2rem !important;
  color: white;
}

.datepicker,
.react-datepicker__month-container,
.react-datepicker__year--container {
  font-size: 18px;
}

.datepicker .react-datepicker__month-wrapper,
.react-datepicker__year-wrapper {
  display: flex;
  justify-content: space-evenly;
}

/**
 * etc
 */
svg.MuiSvgIcon-root {
  cursor: pointer;
}

.MuiBackdrop-root.MuiModal-backdrop {
  background: #0000004a !important;
}

th.MuiTableCell-root.MuiTableCell-head {
  color: #fff;
  font-weight: 100;
  background: #2e5495 !important;
}

button {
  /* font-size: 1.6rem !important; */
  font-family: "Kanit", sans-serif !important;
}
